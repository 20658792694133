import React from 'react';
import FAQItem from '../../components/FAQ-item';
import Section from '../../components/section';
import Layout from '../../components/layout';
import './styles.scss';

const FAQ = () => {
  return (
    <Layout>
      <Section light pagehero lgPd>
        <h1 className="f_size_48 f_600 t_color4 l_height54 text-center">Hexospark FAQ</h1>
      </Section>

      <div className="mb_20 faq-wrapper">
        <FAQItem
          title="How many email accounts can I connect to Hexospark?"
          text={
            <>
              <p>
                You can connect one email account per stack. For example Hexospark 3X can connect 3 email accounts to
                send email campaigns from.
              </p>
            </>
          }
        />
        <FAQItem
          title="What are the benefits of stacking?"
          text={
            <>
              <p>Each additional plan stacked unlocks 1 GB storage and 1 additional email inbox per code.</p>
              <p>Every 5th additional plan stacked unlocks 1 additional workspace and an additional 4 team seats.</p>
            </>
          }
        />
        <FAQItem
          title="Does Hexospark support workspaces?"
          text={
            <>
              <p>Yes, we provide an additional workspace for every 5X stack.</p>
              <p>
                You will be able to assign team members to specific workspaces, ideal for managing different projects
                with their own campaigns and CRM.
              </p>
            </>
          }
        />
        <FAQItem
          title="Can I invite or manage team members?"
          text={
            <>
              <p>Hexospark 1X provides a single account.</p>
              <p>Every 5th stack unlocks 1 additional workspace and an additional 4 team seats.</p>
            </>
          }
        />
        <FAQItem
          title="Can you Import/Export data to and from the CRM?"
          text={<p>Yes, we support CSV import and export of contacts and organizations.</p>}
        />
        <FAQItem
          title="Can I host my data in the EU?"
          text={
            <p>Yes, during signup you will be able to select the server location before adding any data to the CRM.</p>
          }
        />
        <FAQItem
          title="Do I need to provide my own email account?"
          text={<p>Yes, you need to provide your own SMTP or Gmail account to send emails from.</p>}
        />
        <FAQItem
          title="Does Hexospark require Hexomatic?"
          text={
            <p>
              Hexospark works independently enabling you to import/export contacts via traditional CSV files, via our
              browser extension and provide CRM capabilities. Our native integration with Hexomatic also provides
              convenient access to data and leads collected to expedite your business growth.
            </p>
          }
        />

        <FAQItem
          title="Can we change the associated sending email down the road?"
          text={
            <p>Yes, you can change the sending email at any time, but this will delete any associated campaign data.</p>
          }
        />

        <FAQItem
          title="How will the chrome extension work?"
          text={
            <p>
              The extension will pull contact data from the active browser tab making it easy to add the lead you find
              from LinkedIn and websites into the CRM / email campaign and enabling you to use the data to write
              personalized intros.
            </p>
          }
        />

        <FAQItem
          title="Will there be any premium add-ons of any kind or the codes we buy now will cover future releases?"
          text={
            <>
              <p>
                The codes you buy now will cover everything listed on the sales page (Bulk email sending, image
                personalization, CRM, template library, meeting booking, campaign tracking).
              </p>

              <p>
                Most probably we will add more built-in features that will be also included in these plans. If we add
                any additional feature that requires the use of 3rd party services in the future (with an additional
                cost per usage/user) then this will be provided using premium credits.
              </p>
            </>
          }
        />
        <FAQItem
          title="Do you plan to integrate with Hexowatch?"
          text={
            <>
              <p>Yes, it is planned to use Hexowatch as one of the triggers to send an email.</p>
            </>
          }
        />
        <FAQItem
          title="How many emails can I send using Hexospark? "
          text={
            <p>
              We don’t set any email sending limits, but we recommend limiting the number of emails you send per day
              based on your email account age, reputation, and sending history. For example for newer email accounts, it
              is better to start small with 20-100 emails per day and gradually ramp up to ensure your emails reach the
              inbox.
            </p>
          }
        />

        <FAQItem
          title="What is the refund period?"
          text={
            <p>
              All our paid plans are covered by our 30-day refund policy, so if Hexospark is not the right fit for your
              business, we provide a full refund.
            </p>
          }
        />

        <FAQItem
          title="How much storage do I get?"
          text={
            <p>
              Each code provides 1GB of storage which can be used to upload files to contacts inside the CRM and for
              image personalization.
            </p>
          }
        />
      </div>
    </Layout>
  );
};

export default FAQ;
