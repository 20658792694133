import React from 'react';

export const minimizeIcon = (
  <svg width="14" height="3" viewBox="0 0 14 3" fill="#1c1b1" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.915131" y="0.203613" width="13" height="2.25525" rx="1" fill="#1c1b1" />
  </svg>
);

export const plusIcon = (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="#1c1b1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5588 6.12508C12.5588 6.70509 12.0887 7.17514 11.5084 7.17514H7.60884V11.0749C7.60884 11.655 7.13863 12.1251 6.5587 12.1249C6.26869 12.1249 6.00618 12.0076 5.81633 11.8176C5.62624 11.6274 5.50873 11.365 5.50886 11.0752L5.5087 7.17498H1.60867C1.31883 7.17498 1.05653 7.05762 0.866289 6.86738C0.676515 6.67761 0.559082 6.4151 0.559082 6.12508C0.558925 5.54518 1.02897 5.07503 1.60921 5.07503H5.50886V1.17523C5.50886 0.595142 5.97917 0.124939 6.55915 0.124939C7.13892 0.125096 7.60881 0.594828 7.60912 1.17484V5.07503H11.5092C12.089 5.07534 12.5585 5.54518 12.5588 6.12508Z"
      fill="#1c1b1"
    />
  </svg>
);

export const chatFacade = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
    <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path>
  </svg>
);
